<template>
  <div class="container">
    <div class="form-card">
      <div class="card-head">
        <h4 class="card-title">Şifre Güncelle</h4>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Eski Şifre</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.oldPassword"
              />
            </div>
            <span
              v-for="error in v$.formData.oldPassword.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="">Yeni Şifre</label>
              <input
                type="text"
                class="form-control"
                v-model="formData.password"
              />
            </div>
            <span
              v-for="error in v$.formData.password.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
          </div>
          <div class="col-md-6">
            <button class="btn btn-filled-primary" @click="updatePassword">
              Güncelle
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Done
    @closeModal="doneModalClose"
    v-if="isCloseModal"
    doneMsg="Şifreniz Güncellendi"
    doneTitle="Şifre Güncelleme"
    @click="closeModal"
    link="/"
  />
</template>
<script>
import useValidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import Done from "@/components/Done.vue";
export default {
  data() {
    return {
      v$: useValidate(),
      isCloseModal: false,
      formData: {
        oldPassword: "",
        password: "",
      },
    };
  },
  validations() {
    return {
      formData: {
        oldPassword: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        password: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
    };
  },
  components: {
    Done,
  },
  methods: {
    doneModalClose(value) {
      this.isCloseModal = value;
    },
    updatePassword() {
      this.v$.$touch();
      if (!this.v$.$error) {
        this.$axios
          .put("user/change-password", this.formData)
          .then(() => {
            this.isCloseModal = true;
            this.formData = {
              oldPassword: "",
              password: "",
            };
            this.v$.$reset();
          })
          .catch(() => {
            this.formData.oldPassword = "";
            let item = this.v$.$silentErrors.filter(
              (item) => item.$propertyPath == "formData.oldPassword"
            );
            item[0].$message = "Şifre eski şifre ile eşleşmiyor";
          });
      }
    },
  },
  created(){
    this.$store.commit("theme/addToBreadcrumps", [
      {
        title: "Anasayfa",
        path: "/",
      },
      {
        title: "Şifre Güncelle",
      },
    ]);
  }
};
</script>